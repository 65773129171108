<template lang="pug">
//- This component combines banner data with the k-slide-show component
k-slide-show(
  style-type="banner"
  v-if="slides.length > 0"
  :slides="slides"
  :options="options"
)
  template(
    v-if="$slots['slide-info']"
    #slide-info
  )
    slot(name="slide-info")
</template>

<script setup>
import { computed, watch, onMounted } from 'vue'
import { useStore } from 'skid-composables'
import KSlideShow from '@sharedComponents/common/k-slide-show.vue'

const store = useStore()
const props = defineProps({
  useType: { type: String, required: true },
  options: { type: Object, required: true }
})

const fetchData = () => {
  return store.dispatch('banners/allByUseType', {
    filter: props.useType,
    options: {}
  })
}

const banners = computed(() => {
  return store.getters['banners/allByType'](props.useType)
})

const slides = computed(() => {
  return banners.value.map((banner) => {
    return {
      id: banner.id,
      image: {
        url: banner.image.url
      },
      mobile_image: {
        url: banner.mobile_image.url
      },
      link: banner.url
    }
  })
})

watch(() => props.useType, fetchData)

onMounted(async () => {
  fetchData()
})
</script>
