<template lang="pug">
.k-slide-show(
  :is="component"
  :slides="slides"
  :options="options"
)
  template(
    v-if="$slots['slide-info']"
    #slide-info
  )
    slot(name="slide-info")
</template>

<script setup>
import { computed, ref, unref } from 'vue'
import { swiper, swiperSlide, useSwiper } from 'skid-composables'
import InCard from './k_slide_show/in-card.vue'
import Banner from './k_slide_show/banner.vue'

const STYLE_TYPE_MAP = {
  'in-card': InCard,
  banner: Banner
}

const props = defineProps({
  styleType: { type: String },
  slides: { type: Array, required: true },
  options: { type: Object }
})

const component = computed(() => {
  return STYLE_TYPE_MAP[props.styleType]
})
</script>
