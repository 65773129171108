<template lang="pug">
.vc-shared-common-k-slide-show-banner-caption-view(:class="containerClass")
  .caption1(v-if="props.caption1") {{ props.caption1 }}
  .caption2(v-if="props.caption2") {{ props.caption2 }}
  .text-wrapper
    .caption3(v-if="props.caption3") {{ props.caption3 }}
    .wrapper
      .caption4(v-if="props.caption4") {{ props.caption4 }}
      .caption5(v-if="props.caption5") {{ props.caption5 }}
</template>

<script setup>
import { computed } from 'vue'
import BannerSlideShow from '@sharedComponents/banner/common/slide-show.vue'

const CAPTION_MODE_MAP = {
  align_center: '-align-center',
  align_left: '-align-left'
}

// props
const props = defineProps({
  captionMode: { type: String },
  caption1: { type: String },
  caption2: { type: String },
  caption3: { type: String },
  caption4: { type: String },
  caption5: { type: String }
})

const containerClass = computed(() => {
  return [CAPTION_MODE_MAP[props.captionMode]].filter(Boolean).join(' ')
})

const captionModes = computed(() => CAPTION_MODE_MAP)

defineExpose({
  captionModes
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/font.sass'
@use '@shared/css/abstracts/size.sass'

.vc-shared-common-k-slide-show-banner-caption-view
  position: absolute
  z-index: 5
  color: color.$white

  &.-align-center
    top: calc(100% * calc(var(--mobile-caption-y-ratio)))
    left: 50%
    transform: translate(-50%, -50%)
    +utils.from(size.$ipad)
      top: calc(100% * calc(var(--caption-y-ratio)))
      left: 50%

  &.-align-left
    top: calc(100% * calc(var(--mobile-caption-y-ratio)))
    left: var(--mobile-caption-left-x)
    transform: translate(0, -50%)
    +utils.from(size.$ipad)
      top: calc(100% * calc(var(--caption-y-ratio)))
      left: var(--caption-left-x)

  .caption1
    font-size: 40px
    font-weight: font.$bold
    line-height: 1.2
    +utils.from(size.$ipad)
      font-size: 78px

  .caption2
    font-size: 30px
    font-weight: font.$bold
    line-height: 1.2
    +utils.from(size.$ipad)
      font-size: 60px

  .caption3
    font-size: 26px
    font-weight: font.$bold
    line-height: 1.2
    +utils.from(size.$ipad)
      font-size: 60px

  .caption4
    font-size: 14px
    font-weight: font.$bold
    +utils.from(size.$ipad)
      font-size: 20px

  .caption5
    font-size: 16px
    font-weight: font.$bold
    +utils.from(size.$ipad)
      font-size: 24px

  > .text-wrapper
    display: flex
    gap: .5rem
    align-items: center
</style>
