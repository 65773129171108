<template lang="pug">
//- This component refers to app/javascript/src/application/js/components/common/slideshow-container.vue

.vc-shared-common-k-slide-show-banner(
  :class="containerClass"
  :style="containerStyle"
)
  .slideshow-wrapper
    swiper(
      :options="swiperOptions"
      ref="swiperRef"
    )
      .overlay(v-if="options.hasOverlay")

      swiper-slide(
        v-for="slide in slides"
        :key="slide.id"
      )
        a.link(
          :href="slide.link"
          :target="slide.target || '_blank'"
        )
          img.image(
            :src="isMobile ? slide.mobile_image?.url || slide.image.url : slide.image.url"
          )
      .swiper-pagination(slot="pagination")
        span test pagination
    caption-view(
      v-if="options.captionMode"
      ref="captionViewElm"
      :caption-mode="options.captionMode"
      :caption1="options.caption1"
      :caption2="options.caption2"
      :caption3="options.caption3"
      :caption4="options.caption4"
      :caption5="options.caption5"
    )
  //- .decoration-stripe(v-if="hasDecoration")
</template>

<script setup>
import { computed, ref, unref } from 'vue'
import { useStore } from 'skid-composables'
import { swiper, swiperSlide, useSwiper } from 'skid-composables'
import captionView from './banner/caption-view.vue'

const DEFAULT_HEIGHT_TO_WIDTH_RATIO = 35
const DEFAULT_MOBILE_HEIGHT_TO_WIDTH_RATIO = 62.5
const DEFAULT_BORDER_RATIUS = 16
const DEFAULT_MOBILE_BORDER_RATIUS = 16

const MOBILE_IMAGE_BREAK_POINT = 769

const props = defineProps({
  slides: { type: Array, required: true },
  options: {
    type: Object,
    default: () => {
      return {
        bollutStyle: null,
        hasOverlay: false,
        borderRadius: null,
        mobileBorderRadius: null,
        heightToWidthRatio: null,
        mobileHeightToWidthRatio: null,
        isBelowCompMoveUp: null,
        // for caption
        captionMode: null,
        caption1: '',
        caption2: '',
        caption3: '',
        caption4: '',
        caption5: ''
      }
    }
  }
})

const captionViewElm = ref(null)

const { swiperOptions } = useSwiper({})
const swiperRef = ref(null)
const store = useStore()

const isMobile = computed(() => {
  // workaround for safari can't get window width from vuex at first time loading
  return (
    (store.getters['windowWidth'] || window.innerWidth) <
    MOBILE_IMAGE_BREAK_POINT
  )
})

const containerClass = computed(() => {
  return [
    props.options.bulletStyle === 'line' ? 'is-bullet-line' : null,
    props.options.captionMode
      ? captionViewElm.value?.captionModes[props.options.captionMode]
      : null,
    props.options.isBelowCompMoveUp ? 'is-below-comp-move-up' : null
  ]
    .filter(Boolean)
    .join(' ')
})

const containerStyle = computed(() => {
  return [
    `--height-to-width-ratio: ${
      props.options.heightToWidthRatio || DEFAULT_HEIGHT_TO_WIDTH_RATIO
    }%`,
    `--mobile-height-to-width-ratio: ${
      props.options.mobileHeightToWidthRatio ||
      DEFAULT_MOBILE_HEIGHT_TO_WIDTH_RATIO
    }%`,
    `--border-radius: ${props.options.borderRadius ?? DEFAULT_BORDER_RATIUS}px`,
    `--mobile-border-radius: ${
      props.options.mobileBorderRadius ?? DEFAULT_MOBILE_BORDER_RATIUS
    }px` // ?? allows 0 to be used
  ]
    .filter(Boolean)
    .join(';')
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/color'
@use '@shared/css/abstracts/font'

.vc-shared-common-k-slide-show-banner
  //- caption
  --mobile-max-caption-height: 100px
  --max-caption-height: 220px
  --mobile-caption-left-x: 5%
  --caption-left-x: 10%
  --mobile-caption-y-ratio: 0.4
  --caption-y-ratio: 0.5

  .slideshow-wrapper
    position: relative
    width: 100%
    height: 100%
    overflow: hidden
    padding-top: var(--mobile-height-to-width-ratio)
    +utils.from(size.$ipad)
      padding-top: var(--height-to-width-ratio)
    > .swiper-container
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      border-radius: var(--mobile-border-radius)
      +utils.from(size.$ipad)
        border-radius: var(--border-radius)

      > .swiper-pagination
        > .swiper-pagination-bullet
          $size: .5rem

          width: $size
          height: $size
          background-color: #B5B5B5
          opacity: 1

        > .swiper-pagination-bullet-active
          background-color: color.$ci
    .swiper-slide > a
      display: block
      width: 100%
      height: 100%
    .swiper-slide > a > img
      display: block
      width: 100%
      height: 100%
      object-fit: cover

    .overlay
      content: ""
      position: absolute
      top: 0px
      left: 0px
      bottom: 0px
      right: 0px
      z-index: 2
      background-image: linear-gradient(200deg, rgba(0, 0, 0, 0) 0%, rgba(12, 10, 8, .6) 100%)

  &.is-bullet-line
    .swiper-container .swiper-pagination
      bottom: .5rem
      display: flex
      max-width: 80%
      transform: translate(-50%, 0)
      justify-content: center
      left: 50%
      > .swiper-pagination-bullet
        width: 30px
        height: 3px
        margin-inline: 2px
        border-radius: unset
        background-color: color.$lighter
        opacity: 1
      > .swiper-pagination-bullet-active
          background-color: color.$ci

  &.-align-left
    .swiper-container .swiper-pagination
      width: auto
      left: var(--mobile-caption-left-x)
      transform: translate(0, 0)
      +utils.from(size.$ipad)
        left: var(--caption-left-x)

  &.is-below-comp-move-up
    margin-bottom: calc(var(--mobile-height-to-width-ratio) * -0.5 + var(--mobile-max-caption-height) / 2 + 20px)
    +utils.from(size.$ipad)
      margin-bottom: calc(var(--height-to-width-ratio) / -2 + var(--max-caption-height) / 2 + 60px)

    .swiper-container .swiper-pagination
      bottom: unset
      top: calc(100% * calc(var(--mobile-caption-y-ratio)) + 60px)
      +utils.from(size.$ipad)
        top: calc(100% * calc(var(--caption-y-ratio)) + var(--max-caption-height) / 2 + 20px)

  .decoration-stripe
    width: 100%
    height: 12px
    background-image: linear-gradient(45deg, rgba(color.$black, .2) 25%, transparent 25%, transparent 50%, rgba(color.$black, .2) 50%, rgba(color.$black, .2) 75%, transparent 75%, transparent)
    background-size: 4px 4px
</style>
