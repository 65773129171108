<template lang="pug">
//- This component is designed to be placed inside a card.

.k-slide-show-in-card(:class="{ 'skip-rounded': options.skipRounded }")
  .slideshow-wrapper
    .cover(v-if="options.hasCover")
    .overlay(v-if="options.overlay")
      span.text {{ options.overlay.text }}
    .indicator(v-if="options.indicator")
      span.text {{ options.indicator.text }}
    .slide-progress-text(v-if="options.hasProgressText")
      span {{ slideProgressText }}
    swiper(
      :options="swiperOptions"
      ref="swiperRef"
    )
      swiper-slide(
        v-for="slide in slides"
        :key="slide.id"
      )
        a.link(
          :href="slide.link"
          :target="slide.target || '_blank'"
        )
          img.image(:src="slide.image.url")
          img.mobile-image(:src="slide.mobile_image?.url || slide.image.url")
      .swiper-pagination(slot="pagination")
    .slide-info
      slot(name="slide-info")
</template>

<script setup>
import { computed, ref, unref } from 'vue'
import { swiper, swiperSlide, useSwiper } from 'skid-composables'

const props = defineProps({
  slides: { type: Array, required: true },
  options: {
    type: Object,
    default: () => {
      return {
        skipRounded: false,
        hasProgressText: false,
        overlay: null,
        indicator: null,
        hasCover: false
      }
    }
  }
})

const { swiperOptions } = useSwiper({})
const swiperRef = ref(null)
const currentHumanIndex = computed(() => {
  if (!!unref(swiperRef)) {
    return unref(swiperRef).swiper.activeIndex + 1
  } else {
    return 1
  }
})

const slideProgressText = computed(() => {
  return `${currentHumanIndex.value}/${props.slides.length}`
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/color'
@use '@shared/css/abstracts/font'

.k-slide-show-in-card
  --slide-height: 200px

  height: var(--mobile-slide-height, var(--slide-height))
  +utils.from(size.$ipad)
    height: var(--slide-height)

  .slideshow-wrapper
    position: relative
    width: 100%
    height: 100%
    overflow: hidden
    border-radius: size.$gap
    > .swiper-container
      position: absolute
      inset: 0
      overflow: unset
      > .swiper-pagination
        bottom: .5rem
        display: flex
        max-width: 80%
        transform: translate(-50%, 0)
        justify-content: center
        left: 50%
        > .swiper-pagination-bullet
          width: 30px
          height: 3px
          margin-inline: 2px
          border-radius: unset
          background-color: color.$lighter
          opacity: 1
        > .swiper-pagination-bullet-active
            background-color: color.$ci
    .swiper-slide > a
      display: block
      width: 100%
      height: 100%
    .swiper-slide > a > img
      display: block
      width: 100%
      height: 100%
      object-fit: cover
      &.image
        display: none
      +utils.from(size.$ipad)
        &.image
          display: block
        &.mobile-image
          display: none

  .slide-progress-text
    position: absolute
    top: size.$gap * .5
    right: size.$gap * .5
    padding: .25em .75em
    border-radius: 1em
    background-color: color.$black
    opacity: .66
    font-size: font.$small
    color: color.$white
    letter-spacing: 2px
    z-index: 4

  .indicator
    $background-color: color.$tickle-me-pink
    $triangle-width: 5px
    $triangle-height: 12px
    font-size: font.$size-7
    display: block
    position: absolute
    left: 0
    top: size.$gap
    background-color: $background-color
    color: color.$white
    padding: .25em
    padding-left: .5em
    z-index: 3
    &:after,
    &:before
      position: absolute
      right: -$triangle-width
      display: block
      content: ''
      width: 0
      height: 0
      border-style: solid
    &:after
      border-width: $triangle-height $triangle-width 0 0
      top: 0
      border-color: $background-color transparent transparent transparent
    &:before
      bottom: 0
      border-width: $triangle-height 0 0 $triangle-width
      border-color: transparent transparent transparent $background-color
    .text:before
      content: '# '

  .overlay
    position: absolute
    top: 0
    z-index: 3
    height: 100%
    width: 100%
    border-radius: size.$gap
    background-color: rgba(color.$black, 0.64)
    color: white
    display: flex
    justify-content: center
    align-items: center
    font-size: font.$size-6
    .text:before
      content: '# '

  .cover
    content: ''
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0
    z-index: 2
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.34))

  .slide-info
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    color: color.$white
    z-index: 5

  &.skip-rounded
    .slideshow-wrapper
      border-radius: unset
</style>
